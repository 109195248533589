<template>
    <div class="apiAuthorization">
		<!-- 设置API -->
		<!-- <common-head url="/newHome" :title="$t('tba.APIAuthorization')" :is-return="true" @onclickRight="onclickRight">
			<span class="text" slot="text">{{ $t('tba.helpGuide') }}</span>
		</common-head> -->
		<van-nav-bar
		    :title="$t('tba.APIAuthorization')"
		    left-arrow
		    @click-left="onClickLeft"
			:right-text="$t('tba.helpGuide')"
			@click-right="onclickRight"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div style="padding-top: calc(env(safe-area-inset-top) + 0.88rem);">
			<div class="apiList bgWhite">
			    <ul>
			        <!-- <li @click="btnChange({id: 1, name: 'HUOBI'})">
                        <div class="leftBox">
                            <div class="img-icon">
                                <img src="@/assets/imgs/new_color/api-huobi.png">
                            </div>
                            <span>HUOBI</span>
                        </div>
			            <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
			        </li> -->
			        <li @click="btnChange({id: 2, name: 'BINANCE'})">
                        <div class="leftBox">
                            <div class="img-icon">
                                <img src="@/assets/imgs/new_color/api-bian.png">
                            </div>
                            <span>BINANCE</span>
                        </div>
			            <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
			        </li>
					<li @click="btnChange({id: 3, name: 'OKX'})">
                        <div class="leftBox">
                            <div class="img-icon isokx">
                                <img src="@/assets/imgs/new_color/api-okex.png">
                            </div>
                            <span>OKX</span>
                        </div>
					    <div class="spot"><img src="@/assets/imgs/icon/menu-spot.png"></div>
					</li>
			    </ul>
			</div>
			
			<van-action-sheet
			    v-model="show"
			    :actions="actions"
			    :cancel-text="$t('common.cancel')"
			    close-on-click-action
			    @select="onSelect"
			/>
		</div>
        
    </div>
</template>

<script>
	import commonHead from '@/components/commonHead';
    export default {
		name: 'apiAuthorization',
        components: {
			commonHead,
        },
        data() {
            return {
                show: false,
                apiDataList: [],
                exchange_id: "",
                exchange_name: "",
                actions: [
                    {name:  ""},
                    {name: ""},
                ],
            }
        },
        created() {
			this.actions[0].name = this.$t('tba.SetAPI');
			this.actions[1].name = this.$t('tba.DeleteAPI');
            this.getAPIDataList();
        },
        methods: {
			onClickLeft() {
				this.$router.back(-1);
			},
			onclickRight() {
					  this.$router.push('/consultingService')
			},
            getAPIDataList() {
                this.$post2("Trade/Api/Index/getApiSet").then((res) => {
                    this.apiDataList = res.list;
                });
            },
            btnChange(item) {
                this.exchange_id = item.id;
                this.exchange_name = item.name;
                this.show = true;
            },
            onSelect(item) {
                this.show = false;
                if (item.name == this.$t('tba.DeleteAPI')) {
                    this.$dialog.confirm({
                        title: this.$t('tba.Delete'),
                        message: this.$t('tba.sure_to_delete'),
                    }).then(() => {
                        this.$post2("Trade/Api/Index/delApiKey", {
                            exchange_id: this.exchange_id,
                        }).then(() => {
                            this.$toast({message: this.$t('tba.delete_ok'),}
                            );
                        });
                    }).catch(() => {
                        this.$toast({message: this.$t('tba.delete_no'),});
                    });
                } else if (item.name == this.$t('tba.SetAPI')) {
                    this.$router.push({
                        path: "/viewAPI2",
                        query: {
                            exchange_id: this.exchange_id,
                            exchange_name: this.exchange_name,
                        },
                    });
                }
            },
        }
    };
</script>

<style lang="less" scoped>
    .apiAuthorization {
		font-family: PingFangSC-Regular;
        .apiList {
            margin: 0.2rem 0;
			margin-bottom: 0;
			min-height:calc(91.8vh + 54px);
			border-top-left-radius: 0.32rem;
			border-top-right-radius: 0.32rem;
			// height: 100%;

            li {
                padding: 0.2rem 0;
				margin: 0 0.4rem;
                border-bottom: 1px solid #F1F1F1;
                overflow: hidden;

                display: flex;
                justify-content: space-between;
                align-items: center;
                .leftBox{
                    display: flex;
                    align-items: center;
                    .img-icon {
                        width: 0.61rem;
                        height: 0.61rem;
                        margin-right: 0.22rem;
                        // vertical-align: -0.2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        &.isokx{
                            margin-left: 0.05rem;
                            img{
                                width: 65%;
                                height: 65%;
                            }
                        }
                    }
                }

                .spot {
                    float: right;

                    img {
                        width: 0.28rem;
                    }
                }

                span {
                    font-size: 0.25rem;
                    color: #02172E;
                }
            }

            li:last-child {
                // border-bottom: none;
            }
        }
    }
</style>
